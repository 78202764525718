import CONST from './loadingConstants';

import type { AnyAction } from 'redux';

export const initialState = {
    isLoading: false,
};

const loadingReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case CONST.UPDATE_LOADING:
            return {
                isLoading: action.payload,
            };
        default:
            return state;
    }
};

export default loadingReducer;
