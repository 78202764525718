const CONSTANTS = {
    REPORTS_APPLICATIONS_EXCEL: 'REPORTS_APPLICATIONS_EXCEL',
    REPORTS_APPLICATIONS_REQUEST: 'REPORTS_APPLICATIONS_REQUEST',
    REPORTS_APPLICATIONS_SUCCESS: 'REPORTS_APPLICATIONS_SUCCESS',
    REPORTS_APPLICATIONS_FAILURE: 'REPORTS_APPLICATIONS_FAILURE',

    REPORTS_BUILDINGS_EXCEL: 'REPORTS_BUILDINGS_EXCEL',
    REPORTS_BUILDINGS_REQUEST: 'REPORTS_BUILDINGS_REQUEST',
    REPORTS_BUILDINGS_SUCCESS: 'REPORTS_BUILDINGS_SUCCESS',
    REPORTS_BUILDINGS_FAILURE: 'REPORTS_BUILDINGS_FAILURE',

    REPORTS_USERS_EXCEL: 'REPORTS_USERS_EXCEL',
    REPORTS_USERS_REQUEST: 'REPORTS_USERS_REQUEST',
    REPORTS_USERS_SUCCESS: 'REPORTS_USERS_SUCCESS',
    REPORTS_USERS_FAILURE: 'REPORTS_USERS_FAILURE',

    REPORTS_CONFLICT_APPLICATIONS_EXCEL: 'REPORTS_CONFLICT_APPLICATIONS_EXCEL',
    REPORTS_CONFLICT_APPLICATIONS_REQUEST: 'REPORTS_CONFLICT_APPLICATIONS_REQUEST',
    REPORTS_CONFLICT_APPLICATIONS_SUCCESS: 'REPORTS_CONFLICT_APPLICATIONS_SUCCESS',
    REPORTS_CONFLICT_APPLICATIONS_FAILURE: 'REPORTS_CONFLICT_APPLICATIONS_FAILURE',
};

export const API = {
    applications: '/reports/applications',
    buildings: '/reports/buildings',
    users: '/reports/users',
    conflict_applications: '/reports/conflict_applications',
};

export default CONSTANTS;
