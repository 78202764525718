import { defaultState } from 'redux/utils';

const CONSTANTS = {
    CURRENT_EDITOR: 'APPLICATION_CURRENT_EDITOR',

    DIALOG: 'APPLICATION_DIALOG',

    CURRENT_PDF_REQUEST: 'APPLICATION_CURRENT_PDF_REQUEST',
    CURRENT_PDF_SUCCESS: 'APPLICATION_CURRENT_PDF_SUCCESS',
    CURRENT_PDF_FAILURE: 'APPLICATION_CURRENT_PDF_FAILURE',

    CURRENT_REQUEST: 'APPLICATION_CURRENT_REQUEST',
    CURRENT_SUCCESS: 'APPLICATION_CURRENT_SUCCESS',
    CURRENT_FAILURE: 'APPLICATION_CURRENT_FAILURE',

    HISTORY_REQUEST: 'APPLICATION_HISTORY_REQUEST',
    HISTORY_SUCCESS: 'APPLICATION_HISTORY_SUCCESS',
    HISTORY_FAILURE: 'APPLICATION_HISTORY_FAILURE',

    NOTICES_REQUEST: 'APPLICATION_NOTICES_REQUEST',
    NOTICES_SUCCESS: 'APPLICATION_NOTICES_SUCCESS',
    NOTICES_FAILURE: 'APPLICATION_NOTICES_FAILURE',

    LIST_SELECT: 'APPLICATION_LIST_SELECT',

    LIST_REQUEST: 'APPLICATION_LIST_REQUEST',
    LIST_EXCEL: 'APPLICATION_LIST_EXCEL',
    LIST_SUCCESS: 'APPLICATION_LIST_SUCCESS',
    LIST_FAILURE: 'APPLICATION_LIST_FAILURE',

    LIST_FILTER: 'APPLICATION_LIST_FILTER',

    LIST_TRANSITION: 'APPLICATION_LIST_TRANSITION',

    LIST_NEXT_REQUEST: 'APPLICATION_LIST_NEXT_REQUEST',
    LIST_NEXT_SUCCESS: 'APPLICATION_LIST_NEXT_SUCCESS',

    SET_TRANSFER_REQUEST: 'APPLICATION_SET_TRANSFER_REQUEST',
    SET_TRANSFER_SUCCESS: 'APPLICATION_SET_TRANSFER_SUCCESS',
    SET_TRANSFER_FAILURE: 'APPLICATION_SET_TRANSFER_FAILURE',
};
/*

        application: defaultState,
        history: defaultState,
        notices: defaultState,
        pdf: defaultState
 */
export default CONSTANTS;

export const applicationInitialState = {
    current: {
        editor: false,
        application: defaultState,
        history: defaultState,
        notices: defaultState,
        pdf: defaultState,
    },
    dialog: null,
    transfer: defaultState,
    list: {
        ...defaultState,
        loadingNext: false,
        result: {
            page: 1,
            isLastPage: false,
            select: {},
            transitions: [],
            data: [],
            meta: {},
        },
    },
};
