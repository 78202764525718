import {
    defaultState,
    stateRequest,
    stateSuccess,
    stateFailure,
    stateLevelRequest,
    stateLevelSuccess,
    stateLevelFailure,
    stateOffLoading,
} from '../utils';

import CONST from './applicationsConstant';

import type { AnyAction } from 'redux';

const initialState = {
    current: {
        editor: false,
        application: defaultState,
        history: defaultState,
        notices: defaultState,
        pdf: defaultState,
    },
    dialog: null,
    transfer: defaultState,
    list: {
        ...defaultState,
        loadingNext: false,
        result: {
            page: 1,
            isLastPage: false,
            select: {},
            transitions: [],
            data: [],
            meta: {},
        },
    },
};

const applicationsReducer = (state = initialState, { type, payload }: AnyAction) => {
    switch (type) {
        case CONST.CURRENT_EDITOR:
            return { ...state, current: { ...state.current, editor: payload } };

        case CONST.DIALOG:
            return { ...state, dialog: payload };

        case CONST.LIST_NEXT_REQUEST:
            return { ...state, list: { ...state.list, loadingNext: true } };

        case CONST.LIST_NEXT_SUCCESS:
            return { ...state, list: { ...state.list, loadingNext: false } };

        case CONST.LIST_TRANSITION:
            return {
                ...state,
                list: { ...state.list, result: { ...state.list.result, transitions: payload } },
            };

        case CONST.LIST_SELECT:
            return {
                ...state,
                list: { ...state.list, result: { ...state.list.result, select: payload } },
            };

        case CONST.LIST_EXCEL:
            return stateOffLoading('list', state);

        case CONST.LIST_REQUEST:
            return stateRequest('list', state);

        case CONST.LIST_SUCCESS:
            return stateSuccess('list', payload, state);

        case CONST.LIST_FAILURE:
            return stateFailure('list', state, initialState.list.result);

        case CONST.CURRENT_REQUEST:
            return stateLevelRequest('current', 'application', state);

        case CONST.CURRENT_SUCCESS:
            return stateLevelSuccess('current', 'application', payload, state);

        case CONST.CURRENT_FAILURE:
            return stateLevelFailure('current', 'application', state);

        case CONST.CURRENT_PDF_REQUEST:
            return stateLevelRequest('current', 'pdf', state);

        case CONST.CURRENT_PDF_SUCCESS:
            return stateLevelSuccess('current', 'pdf', payload, state);

        case CONST.CURRENT_PDF_FAILURE:
            return stateLevelFailure('current', 'pdf', state);

        case CONST.HISTORY_REQUEST:
            return stateLevelRequest('current', 'history', state);

        case CONST.HISTORY_SUCCESS:
            return stateLevelSuccess('current', 'history', payload, state);

        case CONST.HISTORY_FAILURE:
            return stateLevelFailure('current', 'history', state);

        case CONST.NOTICES_REQUEST:
            return stateLevelRequest('current', 'notices', state);

        case CONST.NOTICES_SUCCESS:
            return stateLevelSuccess('current', 'notices', payload, state);

        case CONST.NOTICES_FAILURE:
            return stateLevelFailure('current', 'notices', state);

        case CONST.SET_TRANSFER_REQUEST:
            return stateRequest('transfer', state);

        case CONST.SET_TRANSFER_SUCCESS:
            return stateSuccess('transfer', payload, state);

        case CONST.SET_TRANSFER_FAILURE:
            return stateFailure('transfer', state);

        default:
            return state;
    }
};

export default applicationsReducer;
