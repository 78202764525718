import { createContext } from 'react';

import type {
  ContextValueType,
} from './types';

export const AuthContext = createContext<ContextValueType>({
  loading: true,
  status: 'loading',
  error: undefined,
  result: null,
  set: () => {},
  merge: () => {},
  execute: () => Promise.resolve(null),
  reset: () => {},
  currentPromise: Promise.resolve(null),
  currentParams: null,
});
