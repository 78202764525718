import { booleanOptions } from 'utils/form/constants';
import { Filter } from './filtersTypes';

const CONSTANTS = {
    REQUEST: 'FILTER_REQUEST',
    SUCCESS: 'FILTER_SUCCESS',
    FAILURE: 'FILTER_FAILURE',
};

export const CLASSIFIERS = {
    // organisations
    'district': {
        classifier: 'district',
        path: '/classifier/districts',
        name: 'Район',
        single: false,
    },
    'institutionStatus': {
        classifier: 'institutionStatus',
        path: '/classifier/institution/statuses',
        name: 'Статус',
        single: false,
    },
    'institutionType': {
        classifier: 'institutionType',
        path: '/classifier/institution/types',
        name: 'Тип организации',
        single: false,
    },
    'kindOKFS': {
        classifier: 'kindOKFS',
        path: '/classifier/institution/o_k_f_s_kinds',
        name: 'Организационно-правовая форма',
        single: false,
    },
    'institution.jurisdiction': {
        classifier: 'institution.jurisdiction',
        path: '/classifier/jurisdictions',
        name: 'Ведомственная принадлежность',
        single: true,
    },

    //operations
    'entity': {
        classifier: 'entity',
        path: '/classifier/log/log_entities',
        name: 'Объект',
        single: false,
    },
    'action': {
        classifier: 'action',
        path: '/classifier/log/log_actions',
        name: 'Действие',
        single: false,
    },

    'role': { classifier: 'role', path: '/roles', name: 'Роль', single: true },
    'roles.role': { classifier: 'roles.role', path: '/roles', name: 'Роли', single: false },
    //reports
    'institution': {
        classifier: 'institution',
        path: '/institutions',
        name: 'Организация',
        single: true,
    },
    'jurisdiction': {
        classifier: 'jurisdiction',
        path: '/classifier/jurisdictions/permitted_select',
        name: 'Ведомственная принадлежность',
        single: true,
    },
    //applicaitons

    'permitted': {
        classifier: 'permitted' as 'permitted' | 'roles.institution',
        path: '/institutions/permitted',
        name: 'Организация',
        single: true,
    },
    'applicationStatus': {
        classifier: 'applicationStatus',
        path: '/classifier/application/application_statuses',
        name: 'Статус заявления',
        single: true,
    },
    'source': {
        classifier: 'source',
        path: '/classifier/application/application_sources',
        name: 'Источник поступления',
        single: true,
    },
    'parallel': {
        classifier: 'parallel',
        path: '/classifier/parallels',
        name: 'Параллель',
        single: true,
    },
    // 'student.sex':{ classifier: 'student.sex',path: '/classifier/sexes', name: 'Пол', single: true },
    'period': {
        classifier: 'period',
        path: '/classifier/periods',
        name: 'Год обучения',
        single: true,
    },
    'benefit': {
        classifier: 'benefit',
        path: '/classifier/benefits',
        name: 'Преимущественное право на зачисление',
        single: true,
    },
    'adaptiveKind': {
        classifier: 'adaptiveKind',
        path: '/classifier/adaptive_program_kinds',
        name: 'Виды адаптированных программ',
        single: true,
    },
    'representativeTypes': {
        classifier: 'representativeTypes',
        path: '/classifier/representative_types',
        name: 'Тип законного представителя',
        single: true,
    },
    'nationalities': {
        classifier: 'nationalities',
        path: '/classifier/nationalities',
        name: 'Национальность',
        single: true,
    },
    'identityTypes': {
        classifier: 'identityTypes',
        path: '/classifier/identity_types',
        name: 'Тип документа, удостоверяющего личность',
        single: true,
    },
    'isConflict': {
        classifier: 'isConflict',
        single: true,
        name: 'Направлен из конфликтной комиссии',
        values: [
            {
                id: 'conflict',
                name: 'Да',
            },

            {
                id: 'noConflict',
                name: 'Нет',
            },
        ],
    },
    'haveBenefit': {
        classifier: 'haveBenefit',
        single: true,
        name: 'Наличие льгот',
        values: [
            {
                id: 'have',
                name: 'Да',
            },

            {
                id: 'havent',
                name: 'Нет',
            },
        ],
    },
    'sort': {
        classifier: 'sort',
        single: true,
        name: 'Сортировка списка',
        values: [
            {
                id: 'queue',
                name: 'По льготе',
            },

            {
                id: 'dateCreated',
                name: 'По дате подачи',
            },
        ],
    },
    'status': {
        classifier: 'status',
        name: 'Статус',
        single: true,
    },

    // SPO
    'specialty': {
        classifier: 'specialty',
        path: '/classifier/specialities',
        name: 'Специальность',
    },
    'educationForm': {
        classifier: 'educationForm',
        name: 'Форма обучения',
        path: '/classifier/education_forms',
    },
    'educationLevel': {
        classifier: 'educationLevel',
        path: '/classifier/education_levels',
    },
    'educationDocumentType': {
        classifier: 'educationDocumentType',
        path: '/classifier/education_document_type',
    },
    'genders': {
        classifier: 'genders',
        path: '/classifier/genders',
    },
    'identityType': {
        classifier: 'identityType',
        path: '/classifier/identity_types',
    },
    'payForm': {
        classifier: 'payForm',
        name: 'Форма оплаты',
        path: '/classifier/pay_forms',
    },
    'specialConditions': {
        classifier: 'specialConditions',
        single: true,
        name: 'Льгота',
        values: booleanOptions,
    },
    'firstTimeSpo': {
        classifier: 'firstTimeSpo',
        single: true,
        name: 'Первое получение СПО',
        values: booleanOptions,
    },
    'spoSource': {
        classifier: 'spoSource',
        path: '/classifier/application_sources',
        name: 'Источник подачи',
        single: true,
    },

    'achievement': {
        classifier: 'achievement',
        path: '/classifier/application_achievements',
        name: 'Индивидуальные достижения',
        single: true,
    },
};

export const getClassifier = (classifier: keyof typeof CLASSIFIERS) => {
    return CLASSIFIERS[classifier] as Filter;
};

export default CONSTANTS;
