import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { lazy } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SuperContext } from 'react-super-context';
import moment from 'moment';
import 'moment/locale/ru';

import { InstanceConfigContext } from './InstanceConfig';

import { AuthProvider } from './containers/Auth';
import * as serviceWorker from './registerServiceWorker';
import { store } from './redux/store';

const App = lazy(() =>
    getProject() === 'spo' ? import('projects/spo/App') : import('projects/schools/App')
);

import 'pnotify/dist/PNotifyBrightTheme.css';
import '@n3/react-date-picker/dist/n3-date-picker.css';
import 'styles/index.css';
import 'styles/mui-adaptive.css';
import 'styles/pnotify-adaptive.css';
import getProject from 'InstanceConfig/getProject';

moment.locale('ru');

const queryClient = new QueryClient();

const rootNode = document.getElementById('root');

if (rootNode) {
    const root = createRoot(rootNode);

    root.render(
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <SuperContext contexts={[InstanceConfigContext]}>
                    <AuthProvider>
                        <App />
                    </AuthProvider>
                </SuperContext>
            </Provider>
        </QueryClientProvider>
    );
}

serviceWorker.unregister();
