import {
    defaultState,
    stateRequest,
    stateSuccess,
    stateFailure,
    stateLevelRequest,
    stateLevelSuccess,
    stateLevelFailure,
    stateOffLoading,
} from '../utils';

import CONST from './conflictApplicationsConstant';

import type { AnyAction } from 'redux';

const initialState = {
    current: {
        editor: false,
        application: defaultState,
        history: defaultState,
        notices: defaultState,
        pdf: defaultState,
    },
    dialog: null,
    transfer: defaultState,
    list: {
        ...defaultState,
        loadingNext: false,
        result: {
            page: 1,
            isLastPage: false,
            select: {},
            transitions: [],
            data: [],
            meta: {},
        },
    },
};

const conflictApplicationsReducer = (state = initialState, { type, payload }: AnyAction) => {
    switch (type) {
        case CONST.HISTORY_REQUEST:
            return stateLevelRequest('current', 'history', state);

        case CONST.HISTORY_SUCCESS:
            return stateLevelSuccess('current', 'history', payload, state);

        case CONST.HISTORY_FAILURE:
            return stateLevelFailure('current', 'history', state);

        default:
            return state;
    }
};

export default conflictApplicationsReducer;
