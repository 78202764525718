import CONST from './reportsConstant';
import {
    defaultState,
    stateRequest,
    stateSuccess,
    stateFailure,
    stateOffLoading,
} from 'redux/utils';

import type { AnyAction } from 'redux';

export const initialState = {
    applicationsData: defaultState,
    buildingsData: defaultState,
    usersData: defaultState,
    conflictApplicationsData: defaultState,
};

const reportsReducer = (state = initialState, action: AnyAction) => {
    const { type, payload } = action;

    switch (type) {
        case CONST.REPORTS_APPLICATIONS_EXCEL:
            return stateOffLoading('applicationsData', state);
        case CONST.REPORTS_APPLICATIONS_REQUEST:
            return stateRequest('applicationsData', state);
        case CONST.REPORTS_APPLICATIONS_SUCCESS:
            return stateSuccess('applicationsData', payload, state);
        case CONST.REPORTS_APPLICATIONS_FAILURE:
            return stateFailure('applicationsData', state);

        case CONST.REPORTS_BUILDINGS_EXCEL:
            return stateOffLoading('buildingsData', state);
        case CONST.REPORTS_BUILDINGS_REQUEST:
            return stateRequest('buildingsData', state);
        case CONST.REPORTS_BUILDINGS_SUCCESS:
            return stateSuccess('buildingsData', payload, state);
        case CONST.REPORTS_BUILDINGS_FAILURE:
            return stateFailure('buildingsData', state);

        case CONST.REPORTS_USERS_EXCEL:
            return stateOffLoading('usersData', state);
        case CONST.REPORTS_USERS_REQUEST:
            return stateRequest('usersData', state);
        case CONST.REPORTS_USERS_SUCCESS:
            return stateSuccess('usersData', payload, state);
        case CONST.REPORTS_USERS_FAILURE:
            return stateFailure('usersData', state);

        case CONST.REPORTS_CONFLICT_APPLICATIONS_EXCEL:
            return stateOffLoading('conflictApplicationsData', state);
        case CONST.REPORTS_CONFLICT_APPLICATIONS_REQUEST:
            return stateRequest('conflictApplicationsData', state);
        case CONST.REPORTS_CONFLICT_APPLICATIONS_SUCCESS:
            return stateSuccess('conflictApplicationsData', payload, state);
        case CONST.REPORTS_CONFLICT_APPLICATIONS_FAILURE:
            return stateFailure('conflictApplicationsData', state);
    }

    return state;
};

export default reportsReducer;
