import CONST from './tokenConstants';

type TokenState = string;

type TokenAction = {
    type: 'UPDATE_TOKEN_SUCCESS';
    payload: string;
};

export const initialState = '';

const tokenReducer = (state: TokenState = initialState, action: TokenAction): TokenState => {
    switch (action.type) {
        case CONST.UPDATE_TOKEN_SUCCESS:
            // TO DO: middleware
            window.localStorage.setItem('token', action.payload);

            return action.payload;

        default:
            return state;
    }
};

export default tokenReducer;
