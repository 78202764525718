import CONST from './filtersConstant';
import { defaultState } from 'redux/utils';

import type { AnyAction } from 'redux';
import { Filters } from './filtersTypes';
import { Nullable } from 'types/commonTypes';

export const initialState = { ...defaultState, result: null as Nullable<Filters> };

const filterReducer = (state = initialState, action: AnyAction) => {
    const { type, payload } = action;

    switch (type) {
        case CONST.REQUEST:
            return { ...state, loading: true };

        case CONST.SUCCESS:
            const existingResult = state.result || {};
            return { ...state, loading: false, result: { ...existingResult, ...payload } };

        case CONST.FAILURE:
            return { ...state, loading: false, error: payload };

        default:
            return state;
    }
};

export default filterReducer;
