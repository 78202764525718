import { MethodsType } from 'types/commonTypes';

export const getDefaultSuccessTextMessage = (method: MethodsType) => {
    switch (method) {
        case 'GET':
            return '';
        case 'POST':
        case 'PUT':
            return 'Сохранено';
        case 'DELETE':
            return 'Выполнено';
    }
};
