import { AnyAction, Reducer } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import getProject from 'InstanceConfig/getProject';
import spoReducer, { RootState as SpoState } from 'projects/spo/redux/rootReducer';
import schoolsReducer, { RootState as SchoolsState } from 'projects/schools/redux/rootReducer';

type RootReducer = Reducer<RootState, AnyAction>;

const rootReducer: RootReducer = (state, action) => {
    const condition = getProject();
    if (condition === 'spo') {
        return spoReducer(state, action);
    } else {
        return schoolsReducer(state as SchoolsState, action);
    }
};

export type RootState = SpoState | SchoolsState;
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;

export default rootReducer;
