import getProject from 'InstanceConfig/getProject';
import get from 'lodash/get';
import PNotify from 'pnotify/dist/es/PNotify';

const ENV = process.env.REACT_APP_ENV;

const replaceMessage = (str, replace = {}) => {
    if (str && replace instanceof Object && Object.keys(replace)) {
        const keys = Object.keys(replace);
        keys.forEach((key) => {
            str = str.replace(key, replace[key]);
        });
    }
    return str;
};

export const responseError = ({ status, result, replace }) => {
    let dataNotify = { text: `Ошибка сервера - ${status}` };

    if (status === 400) {
        let text = get(result, 'detail');
        if (result.hasOwnProperty('violations')) text = replaceMessage(text, replace);

        dataNotify = { title: 'Произошла ошибка', text };
    }

    PNotify.error(dataNotify);
};

export const errorsHandler = async (response, result, showErrorMessage) => {
    let hasError = false;
    let errors = null;

    switch (true) {
        case response.status === 401:
            return { hasError: true, result, status: response.status };
        case response.status === 500:
            hasError = true;
            let text = 'Ошибка на сервере - 500';
            if (ENV !== 'production') {
                let data = await response.json();
                text = data.detail || data.message;
            }
            PNotify.error({ text });
            break;
        case response.status < 200 || response.status >= 300:
            hasError = true;
            let data = await response.json();
            const message = data.detail || data.message;

            if (message && showErrorMessage) {
                PNotify.error({ text: message });
            }

            errors = [{ name: 'vacant', message: message }];

            switch (getProject()) {
                case 'schools':
                    if (data.violations && Array.isArray(data.violations)) {
                        const mappedErrors = data.violations.map((el) => ({
                            name: el.propertyPath,
                            message: el.message,
                        }));
                        errors = mappedErrors;
                    }
                    break;
                case 'spo':
                    if (Array.isArray(data)) {
                        const mappedErrors = data.map((el) => ({
                            name: el.path,
                            message: el.message,
                        }));
                        errors = mappedErrors;
                    }
                    break;
            }
            break;
    }

    return { hasError, result, errors };
};
