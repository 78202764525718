export const defaultState = {
    loading: false,
    result: null,
};

type State = Record<string, any>;

export const stateOffLoading = (key: keyof State, state: State) => ({
    ...state,
    [key]: {
        ...state[key],
        loading: false,
    },
});

export const stateRequest = (key: keyof State, state: State) => ({
    ...state,
    [key]: { ...state[key], loading: true },
});

export const stateSuccess = (key: keyof State, payload: any, state: State) => ({
    ...state,
    [key]: { ...state[key], loading: false, result: payload },
});

export const stateFailure = (key: keyof State, state: State, defaultResult: any = null) => ({
    ...state,
    [key]: { ...state[key], loading: false, result: defaultResult },
});

export const stateLevelRequest = (baseKey: string, key: keyof State, state: State) => ({
    ...state,
    [baseKey]: { ...state[baseKey], [key]: { ...state[baseKey][key], loading: true } },
});

export const stateLevelSuccess = (
    baseKey: string,
    key: keyof State,
    payload: any,
    state: State
) => ({
    ...state,
    [baseKey]: {
        ...state[baseKey],
        [key]: { ...state[baseKey][key], loading: false, result: payload },
    },
});

export const stateLevelFailure = (
    baseKey: string,
    key: keyof State,
    state: State,
    defaultResult: any = null
) => ({
    ...state,
    [baseKey]: {
        ...state[baseKey],
        [key]: { ...state[baseKey][key], loading: false, result: defaultResult },
    },
});
