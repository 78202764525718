import CONST from './roleConstants';

import type { AnyAction } from 'redux';

const roleReducer = (state = {}, action: AnyAction) => {
    switch (action.type) {
        case CONST.UPDATE_ROLE:
            return action.payload;

        default:
            return state;
    }
};

export default roleReducer;
