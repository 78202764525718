import PNotify from 'pnotify/dist/es/PNotify';
import 'pnotify/dist/es/PNotifyButtons';

import { store } from 'redux/store';
import { InputsErrors, MethodsType } from 'types/commonTypes';
import { errorsHandler } from './error';
import { getDefaultSuccessTextMessage } from './success';

type Params = {
    data?: Record<string, any>;
    headers?: { [key: string]: string };
};

type AllertMessages = {
    successMessage?: string;
    showErrorMessage?: boolean;
};

export const authHeaders = () => {
    const state = store.getState();
    const token = state.token;
    return token ? { Authorization: `Bearer ${token}` } : ({} as { [key: string]: string });
};

export const HOST = process.env.REACT_APP_API_DOMAIN;

const request = <T>(
    method: MethodsType,
    url: string,
    { data, headers = {} }: Params,
    { successMessage, showErrorMessage = true }: AllertMessages
): Promise<{ hasError: boolean; result: T; errors?: InputsErrors; status?: number }> => {
    const newHeaders = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        ...authHeaders(),
        ...headers,
    };

    url = HOST + url;

    return fetch(url, {
        method,
        mode: 'cors',
        headers: newHeaders,
        body: data ? JSON.stringify(data) : undefined,
    })
        .then(async (response) => {
            let result = null as T;

            try {
                const { hasError, errors, status } = await errorsHandler(
                    response,
                    result,
                    showErrorMessage
                );

                if (hasError) {
                    return { hasError: true, result, errors, status };
                }

                if (response.status == 204) {
                    PNotify.success({ text: getDefaultSuccessTextMessage(method) });
                    return { hasError: false, result };
                }

                if (newHeaders['Accept'] === 'application/json') {
                    let text =
                        successMessage != undefined
                            ? successMessage
                            : getDefaultSuccessTextMessage(method);

                    if (text) {
                        PNotify.success({ text });
                    }

                    result = (await response.json()) as T;
                } else {
                    result = (await response.blob()) as unknown as T;
                }

                return { hasError, result };
            } catch (error) {
                return { hasError: true, result };
            }
        })
        .catch((err) => {
            return { hasError: true, result: null as T };
        });
};

export const get = <T>(url: string, params: Params = {}, allertMessages: AllertMessages = {}) =>
    request<T>('GET', url, params, allertMessages);

export const post = <T>(
    url: string,
    params: Params = {},
    allertMessages: AllertMessages = { successMessage: 'Сохранено' }
) => request<T>('POST', url, params, allertMessages);

export const put = <T>(
    url: string,
    params: Params = {},
    allertMessages: AllertMessages = { successMessage: 'Сохранено' }
) => request<T>('PUT', url, params, allertMessages);

export const del = <T>(
    url: string,
    params: Params = {},
    allertMessages: AllertMessages = { successMessage: 'Выполнено' }
) => request<T>('DELETE', url, params, allertMessages);
