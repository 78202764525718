import CONST from './reportsConstant';
import {
    defaultState,
    stateRequest,
    stateSuccess,
    stateFailure,
    stateOffLoading,
} from 'redux/utils';

import type { AnyAction } from 'redux';

export const initialState = {
    applicationsData: defaultState,
    usersData: defaultState,
};

const reportsReducer = (state = initialState, action: AnyAction) => {
    const { type, payload } = action;

    switch (type) {
        case CONST.REPORTS_APPLICATIONS_EXCEL:
            return stateOffLoading('applicationsData', state);
        case CONST.REPORTS_APPLICATIONS_REQUEST:
            return stateRequest('applicationsData', state);
        case CONST.REPORTS_APPLICATIONS_SUCCESS:
            return stateSuccess('applicationsData', payload, state);
        case CONST.REPORTS_APPLICATIONS_FAILURE:
            return stateFailure('applicationsData', state);

        case CONST.REPORTS_USERS_EXCEL:
            return stateOffLoading('usersData', state);
        case CONST.REPORTS_USERS_REQUEST:
            return stateRequest('usersData', state);
        case CONST.REPORTS_USERS_SUCCESS:
            return stateSuccess('usersData', payload, state);
        case CONST.REPORTS_USERS_FAILURE:
            return stateFailure('usersData', state);
    }

    return state;
};

export default reportsReducer;
