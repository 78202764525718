import CONST from './userInfoConstants';

import type { AnyAction } from 'redux';

export const initialState = {};

const userInfoReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case CONST.UPDATE_USERINFO_SUCCESS:
            return action.payload;

        default:
            return state;
    }
};

export default userInfoReducer;
