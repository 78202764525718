import type { PropsWithChildren, ReactElement } from 'react';
import { useAsync } from 'react-async-hook';

import { AuthContext } from './AuthContext';

import type { UserProfileType } from './types';
import { get } from 'services/request';
import { useInstanceConfig } from 'InstanceConfig';

export function AuthProvider({ children }: PropsWithChildren): ReactElement {
    const { getProjectConfigValue } = useInstanceConfig();

    const url = getProjectConfigValue('userProfileUrl') as string;

    const value = useAsync(async () => {
        let userProfile: UserProfileType | null = null;

        try {
            const { hasError, result } = await get<UserProfileType>(url);
            if (!hasError) {
                userProfile = result;
            }
        } catch (e) {}

        return userProfile;
    }, []);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
