import { useCallback } from 'react';

import { createSuperContext } from 'react-super-context';

import logo from '../assets/icons/logo.svg';
import logoPetrobr from '../assets/icons/logo.petrobr.svg';

import { ConfigReturnType, InstanceConfigValues, ProjectConfigValues } from './types';
import getProject from './getProject';
import getInstance from './getInstance';

const instance = getInstance();

const [InstanceConfigContext, useInstanceConfig] = createSuperContext(() => {
    const getInstanceConfigValue = useCallback(
        (key: keyof InstanceConfigValues): ConfigReturnType => {
            switch (key) {
                case 'isLO':
                    return instance === 'lo';
                case 'isShowApplicationCreateButton':
                    return instance === 'lo' && getProject() === 'schools';

                case 'isShowCheckStatus':
                    return instance === 'lo';

                case 'isShowOperations':
                    return instance === 'lo';

                case 'isShowOrganisationsMap':
                    return instance === 'lo';

                case 'logo':
                    return instance === 'lo' ? logo : logoPetrobr;

                case 'logoAltText':
                    return instance === 'lo'
                        ? 'Современное образование Ленинградской области'
                        : 'Петербургское образование';

                case 'organisationsTitle':
                    return instance === 'lo'
                        ? `${getProjectConfigValue('organisationsTitle')} Ленинградской области`
                        : `${getProjectConfigValue('organisationsTitle')} Санкт-Петербурга`;

                case 'organisationPhoneLabel':
                    return instance === 'lo'
                        ? 'Телефон органа местного самоуправления Ленинградской области, в ведении которого находится общеобразовательная организация'
                        : 'Телефон органа местного самоуправления, в ведении которого находится общеобразовательная организация';

                default:
                    throw new Error(`Unknown key: ${key}`);
            }
        },
        []
    );
    const getProjectConfigValue = useCallback(
        (key: keyof ProjectConfigValues): ConfigReturnType => {
            switch (key) {
                case 'isSPO':
                    return getProject() === 'spo';

                case 'organisationsTitle':
                    return getProject() === 'spo'
                        ? 'Организации среднего профессионального образования'
                        : 'Образовательные организации';

                case 'loginUrl':
                    return getProject() === 'spo' ? '/auth/login' : '/authentication_token';

                case 'userProfileUrl':
                    return getProject() === 'spo' ? '/user/profile/current' : '/users/profile';

                case 'userDetailsProfileUrl':
                    return getProject() === 'spo' ? '/user' : '/users';

                default:
                    throw new Error(`Unknown key: ${key}`);
            }
        },
        []
    );

    return {
        getInstanceConfigValue,
        getProjectConfigValue,
    };
});

export { InstanceConfigContext, useInstanceConfig };
