const CONSTANTS = {
    REPORTS_APPLICATIONS_EXCEL: 'REPORTS_APPLICATIONS_EXCEL',
    REPORTS_APPLICATIONS_REQUEST: 'REPORTS_APPLICATIONS_REQUEST',
    REPORTS_APPLICATIONS_SUCCESS: 'REPORTS_APPLICATIONS_SUCCESS',
    REPORTS_APPLICATIONS_FAILURE: 'REPORTS_APPLICATIONS_FAILURE',

    REPORTS_USERS_EXCEL: 'REPORTS_USERS_EXCEL',
    REPORTS_USERS_REQUEST: 'REPORTS_USERS_REQUEST',
    REPORTS_USERS_SUCCESS: 'REPORTS_USERS_SUCCESS',
    REPORTS_USERS_FAILURE: 'REPORTS_USERS_FAILURE',
};

export const API = {
    applications: '/reports/applications',
    users: '/reports/users',
};

export default CONSTANTS;
